var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":""}},[_c('app-card',{attrs:{"heading":"Driver invoices"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":"","sm3":""}},[(_vm.brandList.length)?_c('v-select',{attrs:{"items":_vm.brandList,"item-text":"name","item-value":"id","label":"Brand","hide-details":"","required":""},model:{value:(_vm.filters.brand),callback:function ($$v) {_vm.$set(_vm.filters, "brand", $$v)},expression:"filters.brand"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('v-menu',{ref:"invoicedAtFrom",attrs:{"close-on-content-click":false,"return-value":_vm.filters.invoicedAtFrom,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "invoicedAtFrom", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "invoicedAtFrom", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":!Boolean(!_vm.monthFilter),"label":"Invoiced at from","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.filters.invoicedAtFrom),callback:function ($$v) {_vm.$set(_vm.filters, "invoicedAtFrom", $$v)},expression:"filters.invoicedAtFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.invoicedAtFromFilterMenu),callback:function ($$v) {_vm.invoicedAtFromFilterMenu=$$v},expression:"invoicedAtFromFilterMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"no-title":"","scrollable":"","range":""},on:{"change":function () {
                _vm.$refs.invoicedAtFrom.save(_vm.filters.invoicedAtFrom)
                _vm.invoicedAtFromFilterMenu = false
              }},model:{value:(_vm.filters.invoicedAtFrom),callback:function ($$v) {_vm.$set(_vm.filters, "invoicedAtFrom", $$v)},expression:"filters.invoicedAtFrom"}})],1)],1),_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('v-menu',{ref:"invoicedAtTo",attrs:{"close-on-content-click":false,"return-value":_vm.filters.invoicedAtTo,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "invoicedAtTo", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "invoicedAtTo", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":!Boolean(!_vm.monthFilter),"label":"Invoiced at to","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.filters.invoicedAtTo),callback:function ($$v) {_vm.$set(_vm.filters, "invoicedAtTo", $$v)},expression:"filters.invoicedAtTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.invoicedAtToFilterMenu),callback:function ($$v) {_vm.invoicedAtToFilterMenu=$$v},expression:"invoicedAtToFilterMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"no-title":"","scrollable":"","range":""},on:{"change":function () {
                _vm.$refs.invoicedAtTo.save(_vm.filters.invoicedAtTo)
                _vm.invoicedAtToFilterMenu = false
              }},model:{value:(_vm.filters.invoicedAtTo),callback:function ($$v) {_vm.$set(_vm.filters, "invoicedAtTo", $$v)},expression:"filters.invoicedAtTo"}})],1)],1),_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('v-menu',{ref:"month",attrs:{"close-on-content-click":false,"return-value":_vm.monthFilter,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.monthFilter=$event},"update:return-value":function($event){_vm.monthFilter=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Month","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.monthFilter),callback:function ($$v) {_vm.monthFilter=$$v},expression:"monthFilter"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.monthFilterMenu),callback:function ($$v) {_vm.monthFilterMenu=$$v},expression:"monthFilterMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":"","type":"month"},on:{"change":function () {
                _vm.$refs.month.save(_vm.monthFilter)
                _vm.monthFilterMenu = false
              }},model:{value:(_vm.monthFilter),callback:function ($$v) {_vm.monthFilter=$$v},expression:"monthFilter"}})],1)],1),_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('v-select',{attrs:{"items":_vm.typeList,"item-text":"text","item-value":"value","label":"Type","hide-details":"","required":""},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('v-text-field',{attrs:{"label":"Search","outline":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('v-btn',{staticClass:"ma-0",attrs:{"disabled":_vm.disabledDeleteinvoices,"dark":"","color":"red"},on:{"click":_vm.deleteReverseCombinedInvoices}},[_vm._v("\n          Delete concept invoice\n        ")])],1),_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('v-btn',{staticClass:"ma-0",attrs:{"disabled":!_vm.selectedInvoices || !_vm.selectedInvoices.length,"dark":"","color":"primary"},on:{"click":_vm.sendReverseCombinedInvoices}},[_vm._v("\n          Send selected invoices\n        ")])],1),_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('v-btn',{staticClass:"ma-0",attrs:{"disabled":_vm.disabledDebitInvoices,"dark":"","color":"primary"},on:{"click":_vm.debitReverseCombinedInvoices}},[_vm._v("\n          Debit selected invoices\n        ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1 reverse-combined-invoices-table",attrs:{"headers":_vm.headers,"items":_vm.reverseCombinedInvoices,"loading":_vm.loading,"hide-actions":""},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header,key){return _c('th',{key:key,class:[
              'column',
              header.customSortable && 'sortable',
              header.customSortable &&
                (_vm.filters.sortDir === 'desc' ? 'desc' : 'asc'),
              header.value === _vm.filters.sort ? 'active' : '' ],on:{"click":function($event){return _vm.changeTableSort(header)}}},[(header.customSortable)?_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("arrow_upward")]):_vm._e(),_vm._v("\n            "+_vm._s(header.text)+"\n          ")],1)}),0)]}},{key:"items",fn:function(props){return [_c('tr',{class:("driver-row " + (props.item.active ? '' : 'inactive-row'))},[_c('td',[(props.item.active)?_c('v-checkbox',{staticClass:"ml-3",attrs:{"hide-details":""},model:{value:(props.item.isSelected),callback:function ($$v) {_vm.$set(props.item, "isSelected", $$v)},expression:"props.item.isSelected"}}):_vm._e()],1),_c('td',[_vm._v(_vm._s(props.item.id))]),_c('td',[_vm._v("\n            "+_vm._s(props.item.type)+"\n            "),(!props.item.active)?_c('span',{staticClass:"make-ellipse"},[_vm._v("\n              (debit sent)\n            ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.momentDateTime(props.item.invoicedAt)))]),_c('td',[_vm._v(_vm._s(props.item.number))]),_c('td',[_vm._v(_vm._s(props.item.driver && props.item.driver.firstName))]),_c('td',[_vm._v(_vm._s(props.item.driver && props.item.driver.lastName))]),_c('td',[_vm._v(_vm._s(props.item.driver && props.item.driver.companyName))]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("\n                    "+_vm._s(props.item.rides && Object.keys(props.item.rides).length)+"\n                    "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("\n                      toc\n                    ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-3",attrs:{"max-width":"300"}},_vm._l((Object.values(props.item.rides)),function(ride,key){return _c('router-link',{key:key,staticClass:"mr-2",attrs:{"to":("/dashboard/ride-overview?rideId=" + (ride.id)),"target":"_blank"}},[_vm._v("\n                    "+_vm._s(ride.id)+"\n                  ")])}),1)],1)],1)]),_c('td',[_vm._v(_vm._s(props.item.price.vatIncl))]),_c('td',[_vm._v(_vm._s(props.item.driver.personnelNumber))]),_c('td',[_c('invoice-pdf',{attrs:{"invoice":props.item,"link-icon":true}})],1),_c('td',[(props.item.sentAt)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check")]):_vm._e()],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }