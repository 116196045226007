<template>
  <v-container grid-list-xl>
    <app-card heading="Driver invoices">
      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm3>
          <v-select
            v-if="brandList.length"
            :items="brandList"
            v-model="filters.brand"
            item-text="name"
            item-value="id"
            label="Brand"
            hide-details
            required
          />
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-menu
            ref="invoicedAtFrom"
            v-model="invoicedAtFromFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.invoicedAtFrom"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.invoicedAtFrom"
                v-bind="attrs"
                :disabled="!Boolean(!monthFilter)"
                label="Invoiced at from"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filters.invoicedAtFrom"
              :first-day-of-week="1"
              no-title
              scrollable
              range
              @change="
                () => {
                  $refs.invoicedAtFrom.save(filters.invoicedAtFrom)
                  invoicedAtFromFilterMenu = false
                }
              "
            />
          </v-menu>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-menu
            ref="invoicedAtTo"
            v-model="invoicedAtToFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.invoicedAtTo"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.invoicedAtTo"
                v-bind="attrs"
                :disabled="!Boolean(!monthFilter)"
                label="Invoiced at to"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filters.invoicedAtTo"
              :first-day-of-week="1"
              no-title
              scrollable
              range
              @change="
                () => {
                  $refs.invoicedAtTo.save(filters.invoicedAtTo)
                  invoicedAtToFilterMenu = false
                }
              "
            />
          </v-menu>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-menu
            ref="month"
            v-model="monthFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="monthFilter"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="monthFilter"
                v-bind="attrs"
                label="Month"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="monthFilter"
              no-title
              scrollable
              range
              type="month"
              @change="
                () => {
                  $refs.month.save(monthFilter)
                  monthFilterMenu = false
                }
              "
            />
          </v-menu>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-select
            :items="typeList"
            v-model="filters.type"
            item-text="text"
            item-value="value"
            label="Type"
            hide-details
            required
          />
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-text-field
            v-model="filters.search"
            label="Search"
            outline
          />
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="disabledDeleteinvoices"
            class="ma-0"
            dark
            color="red"
            @click="deleteReverseCombinedInvoices">
            Delete concept invoice
          </v-btn>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="!selectedInvoices || !selectedInvoices.length"
            class="ma-0"
            dark
            color="primary"
            @click="sendReverseCombinedInvoices">
            Send selected invoices
          </v-btn>
        </v-flex>
        <!-- <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="disabledDeleteinvoices"
            class="ma-0"
            dark
            color="primary"
            @click="reopenReverseInvoices">
            Reopen selected invoices
          </v-btn>
        </v-flex> -->
        <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="disabledDebitInvoices"
            class="ma-0"
            dark
            color="primary"
            @click="debitReverseCombinedInvoices">
            Debit selected invoices
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="headers"
        :items="reverseCombinedInvoices"
        :loading="loading"
        class="elevation-1 reverse-combined-invoices-table"
        hide-actions
      >
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="(header, key) in props.headers"
              :key="key"
              :class="[
                'column',
                header.customSortable && 'sortable',
                header.customSortable &&
                  (filters.sortDir === 'desc' ? 'desc' : 'asc'),
                header.value === filters.sort ? 'active' : '',
              ]"
              @click="changeTableSort(header)"
            >
              <v-icon
                v-if="header.customSortable"
                small
                color="white"
              >arrow_upward</v-icon
              >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr :class="`driver-row ${props.item.active ? '' : 'inactive-row'}`">
            <td>
              <v-checkbox
                v-if="props.item.active"
                v-model="props.item.isSelected"
                class="ml-3"
                hide-details
              />
            </td>
            <td>{{ props.item.id }}</td>
            <td>
              {{ props.item.type }}
              <span
                v-if="!props.item.active"
                class="make-ellipse">
                (debit sent)
              </span>
            </td>
            <td>{{ momentDateTime(props.item.invoicedAt) }}</td>
            <td>{{ props.item.number }}</td>
            <td>{{ props.item.driver && props.item.driver.firstName }}</td>
            <td>{{ props.item.driver && props.item.driver.lastName }}</td>
            <td>{{ props.item.driver && props.item.driver.companyName }}</td>
            <td>
              <div class="d-flex align-items-center">
                <v-menu
                  bottom
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      class="ma-2 white--text"
                      color="secondary"
                      v-on="on"
                    >
                      {{ props.item.rides && Object.keys(props.item.rides).length }}
                      <v-icon
                        right
                        dark
                      >
                        toc
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card
                    class="pa-3"
                    max-width="300">
                    <router-link
                      v-for="(ride, key) in Object.values(props.item.rides)"
                      :key="key"
                      :to="`/dashboard/ride-overview?rideId=${ride.id}`"
                      target="_blank"
                      class="mr-2"
                    >
                      {{ ride.id }}
                    </router-link>
                  </v-card>
                </v-menu>
              </div>
            </td>
            <td>{{ props.item.price.vatIncl }}</td>
            <td>{{ props.item.driver.personnelNumber }}</td>
            <td>
              <invoice-pdf
                :invoice="props.item"
                :link-icon="true"
              />
            </td>
            <td>
              <v-icon
                v-if="props.item.sentAt"
                color="green">check</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </app-card>
  </v-container>
</template>

<script>
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import moment from 'moment-timezone'
import CombinedInvoices from '../../../helpers/combined-invoices'
import InvoicePdf from 'Components/Invoice/InvoicePdf'

export default {
  components: { InvoicePdf },
  mixins: [Request, Brands, CombinedInvoices],
  data () {
    return {
      moment,
      brandList: [],
      headers: [
        { text: 'Select', align: 'center', sortable: false },
        {
          text: 'ID',
          align: 'center',
          sortable: false,
          customSortable: true,
          value: 'id'
        },
        { text: 'Type', align: 'center', sortable: false },
        { text: 'Date invoice', align: 'center', sortable: false },
        { text: 'Invoice nr', align: 'center', sortable: false },
        { text: 'First name', align: 'center', sortable: false },
        { text: 'Last name', align: 'center', sortable: false },
        { text: 'Company', align: 'center', sortable: false },
        { text: 'Rides on invoice', align: 'center', sortable: false },
        { text: 'Total incl VAT', align: 'center', sortable: false },
        { text: 'Uw kenmerk', sortable: false },
        { text: 'PDF', sortable: false },
        { text: 'Sent', sortable: false }
      ],
      reverseCombinedInvoices: [],
      loading: false,
      sorting: [
        {
          title: 'Driver Id',
          name: 'driverId',
          dir: 'asc'
        }
      ],
      filters: {
        brand: null,
        invoicedAtFrom: moment(Date.now()).format('YYYY-MM-DD'),
        invoicedAtTo: moment(Date.now()).format('YYYY-MM-DD'),
        search: '',
        type: null
      },
      monthFilter: null,
      invoicedAtFromFilterMenu: false,
      invoicedAtToFilterMenu: false,
      monthFilterMenu: false,
      groupByList: ['ride', 'driver'],
      csvData: [],
      typeList: [
        { text: 'All', value: null },
        { text: 'Reverse', value: 'reverse' },
        { text: 'Debit', value: 'debit' }
      ]
    }
  },
  computed: {
    selectedInvoices () {
      return this.reverseCombinedInvoices.filter((item) => item.isSelected)
    },
    disabledDeleteinvoices () {
      const isDisabled = this.selectedInvoices.some((item) => item.sentAt)
      return isDisabled || !this.selectedInvoices || !this.selectedInvoices.length
    },
    disabledDebitInvoices () {
      const isDisabled = this.selectedInvoices.some((item) => item.type === 'debit')
      return isDisabled || !this.selectedInvoices || !this.selectedInvoices.length
    }
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.updateParams()
      }
    },
    monthFilter: {
      deep: true,
      handler (newValue, oldValue) {
        this.changeMonthFilter()
      }
    }
  },
  async beforeMount () {
    try {
      const [brandList] = await Promise.all([this.fetchBrands()])

      this.brandList = brandList

      const updatedProps = {}
      const _filters = { ...this.filters }

      if (this.brandList && this.brandList.length) {
        _filters.brand = this.brandList[2].id
      }

      if (this.$route.query.sort) {
        _filters.sort = this.$route.query.sort
        updatedProps.sort = this.$route.query.sort
      } else {
        _filters.sort = this.sorting[0].name
        updatedProps.sort = this.sorting[0].name
      }

      if (this.$route.query.sortDir) {
        _filters.sortDir = this.$route.query.sortDir
        updatedProps.sortDir = this.$route.query.sortDir
      } else {
        _filters.sortDir = this.sorting[0].dir
        updatedProps.sortDir = this.sorting[0].dir
      }

      if (this.$route.query.search) {
        _filters.search = this.$route.query.search
        updatedProps.search = this.$route.query.search
      }

      if (this.$route.query.brand) {
        _filters.brand = parseInt(this.$route.query.brand)
        updatedProps.brand = parseInt(this.$route.query.brand)
      }

      if (this.$route.query.invoicedAtFrom) {
        _filters.invoicedAtFrom = this.$route.query.invoicedAtFrom
        updatedProps.invoicedAtFrom = moment.utc(this.$route.query.invoicedAtFrom).format()
      }

      if (this.$route.query.invoicedAtTo) {
        _filters.invoicedAtTo = this.$route.query.invoicedAtTo
        updatedProps.invoicedAtTo = moment.utc(this.$route.query.invoicedAtTo).format()
      }

      if (this.$route.query.month) {
        this.monthFilter = this.$route.query.month
      }

      if (this.$route.query.type) {
        _filters.type = this.$route.query.type
        updatedProps.type = this.$route.query.type
      }

      if (
        !this.$route.query.month &&
        !this.$route.query.invoicedAtFrom &&
        this.$route.query.invoicedAtTo
      ) {
        this.monthFilter = moment(Date.now())
          .add('month', -1)
          .set('date', 1)
          .set('hours', 24)
          .format('YYYY-MM')
      }

      this.filters = _filters

      this.getReverseCombinedInvoices(updatedProps)
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    async debitReverseCombinedInvoices () {
      this.loading = true
      await this.debitCombinedInvoicesBulk(this.selectedInvoices)

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'Invoices debit successfully'
      })

      this.loading = false
      this.updateParams()
    },
    async sendReverseCombinedInvoices () {
      this.loading = true
      await this.sendCombinedInvoicesBulk(this.selectedInvoices, true)

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'Invoices sent successfully'
      })

      this.loading = false
      this.updateParams()
    },
    async deleteReverseCombinedInvoices () {
      this.loading = true
      await this.deleteCombinedInvoicesBulk(this.selectedInvoices, true)

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'invoices successfully deleted'
      })

      this.loading = false
      this.updateParams()
    },
    changeMonthFilter () {
      if (this.monthFilter) {
        this.filters = {
          ...this.filters,
          invoicedAtFrom: this.moment(this.monthFilter)
            .set('date', 1)
            .format('YYYY-MM-DD'),
          invoicedAtTo: this.moment(this.monthFilter)
            .add('month', 1)
            .set('date', 1)
            .format('YYYY-MM-DD')
        }
      } else {
        this.filters = {
          ...this.filters,
          invoicedAtFrom: null,
          invoicedAtTo: null
        }
      }
    },
    async getReverseCombinedInvoices (params = {}) {
      this.loading = true
      let body = { params: { ...params, pageSize: 200, brands: params.brand } }

      await this.request('GET', `/reverse-invoices`, body, ({ data }) => {
        this.loading = false
        this.reverseCombinedInvoices = data.data
      })
    },
    async reopenReverseInvoices () {
      this.loading = true
      this.reopenReverseInvoicesBulk(this.selectedInvoices)

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'Invoices reopen successfully'
      })

      this.loading = false
      this.updateParams()
    },
    onChangeBrands (brandsId) {
      this.filters.brand = brandsId
    },
    changeDriverFilter (id) {
      this.filters.driverFilter = id
    },
    updateParams () {
      let params = {}

      let updatedRouteQuery = { ...this.$route.query }

      if (this.filters.sort) {
        params.sort = this.filters.sort
        updatedRouteQuery = {
          ...updatedRouteQuery,
          sort: this.filters.sort
        }
      }

      if (this.filters.sortDir) {
        params.sortDir = this.filters.sortDir
        updatedRouteQuery = {
          ...updatedRouteQuery,
          sortDir: this.filters.sortDir
        }
      }

      if (this.filters.search) {
        params.search = this.filters.search
        updatedRouteQuery = {
          ...updatedRouteQuery,
          search: this.filters.search
        }
      } else {
        delete updatedRouteQuery.search
        delete params.search
      }

      if (this.filters.brand) {
        updatedRouteQuery = { ...updatedRouteQuery, brand: this.filters.brand }
        params.brand = this.filters.brand
      } else {
        delete updatedRouteQuery.brand
        delete params.brand
      }

      if (this.filters.driverType) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          driverType: this.filters.driverType
        }
        params.driverType = this.filters.driverType
      } else {
        delete updatedRouteQuery.driverType
        delete params.driverType
      }

      if (this.filters.groupBy) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          groupBy: this.filters.groupBy
        }
        params.groupBy = this.filters.groupBy
      } else {
        delete updatedRouteQuery.groupBy
        delete params.groupBy
      }

      if (this.filters.invoicedAtFrom) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          invoicedAtFrom: this.filters.invoicedAtFrom
        }
        params.invoicedAtFrom = moment.utc(this.filters.invoicedAtFrom).format()
      } else {
        delete updatedRouteQuery.invoicedAtFrom
        delete params.invoicedAtFrom
      }

      if (this.filters.invoicedAtTo) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          invoicedAtTo: this.filters.invoicedAtTo
        }
        params.invoicedAtTo = moment.utc(this.filters.invoicedAtTo).set('hours', 24).format()
      } else {
        delete updatedRouteQuery.invoicedAtTo
        delete params.invoicedAtTo
      }

      if (this.monthFilter) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          month: this.monthFilter
        }
      } else {
        delete updatedRouteQuery.month
      }

      if (this.filters.type) {
        params.type = this.filters.type
        updatedRouteQuery = { ...updatedRouteQuery, type: this.filters.type }
      } else {
        delete updatedRouteQuery.type
        delete params.type
      }

      if (this.filters.driver) {
        const driverId = this.filters.driver.id || this.filters.driver
        params.driver = driverId
        updatedRouteQuery = { ...updatedRouteQuery, driver: driverId }
      } else {
        delete updatedRouteQuery.driver
        delete params.driver
      }

      this.$router.replace({ query: updatedRouteQuery }).catch((err) => err)

      this.getReverseCombinedInvoices(params)
    },
    changeTableSort (headerColumn) {
      if (!headerColumn.customSortable) {
        return null
      }

      this.filters = {
        ...this.filters,
        sort: headerColumn.value,
        sortDir: this.filters.sortDir === 'desc' ? 'asc' : 'desc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reverse-combined-invoices-table table.v-table {
  min-width: 100% !important;
  width: max-content !important;
  max-width: initial !important;

  th {
    text-align: center;
    background-color: #424242 !important;
    color: white !important;
  }
}

.driver-row {
  td {
    text-align: center;
  }
}

.inactive-row {
  background: #dededf !important;
  opacity: 0.5;
}
</style>
